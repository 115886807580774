html{
  height:auto;
  min-height: 100%;
}
 body, #root, .App {
    height: 100vh;
    box-sizing: border-box;
    text-align: center;
  }
  body{
    background-color: #282826;

  }